import React from 'react';
import { graphql } from 'gatsby';
import Img from '@components/Img';
import LayoutTabs from '../../layout/tabs';
import Sponsors from '../../components/Sponsors';
import MLink from '../../components/MLink';
import ContentCards from '../../components/ContentCards';
import HomeStatement from '../../components/HomeStatement';
import './dive.scss';

const EnterprisePage = props => {
  const {
    data: {
      dataYaml: { homepage: data },
    },
    location,
  } = props;

  return (
    <LayoutTabs pathname={location.pathname} route="dive" title="Dive" activeTab="enterprise" className="dive-page">
      <div className="dive-page-content">
        <div className="dive-page-content-header">
          <h2 className="dive-page-content-header-title">
            Operationalize your AI/ML activities and bet on deep collaboration
          </h2>

          <p className="dive-page-content-header-text">
            Save time and resources as you operationalize your ML activities with
            MLReef. Reduce operational risk as you navigate in one platform without
            information loss and increase collaboration throughout your team.
          </p>
        </div>
        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Save resources and improve collaboration
            </h3>
            <p>
              MLReef introduces a modular approach based on git repositories to the
              ML workflow. This way, you and your team will be able to use a very well know
              tool to operationalize, orchestrate and scale your ML activities.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Add modularity to your ML flow
              </li>
              <li className="">
                Orchestrate team efforts and increase collaboration
              </li>
              <li className="">
                Instant sharing of project / content without setup
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_groups.png"
              alt="mlreef | Save resources and improve collaboration"
            />
          </div>
        </div>

        <div className="featured-list reverse">
          <div className="featured-list-content">
            <h3>
              Integrate all roles into your AI/ML life cycle
            </h3>
            <p>
              Consolidate all human resources, from data engineers to DevOps and
              infrastructure, in one environment. MLReef enables orchestration between
              roles to increase efficiency and productivity.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                MLReef includes all major elements of MLOps
              </li>
              <li className="">
                Manage your repositories, infrastructure and deployment
              </li>
              <li className="">
                Elastic, easy and highly flexible environment
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_members.png"
              alt="mlreef | Integrate all roles into your AI/ML life cycle"
            />
          </div>
        </div>

        <div className="featured-list">
          <div className="featured-list-content">
            <h3>
              Manage your knowledge base, teams and budgets
            </h3>
            <p>
              MLReef is a single application for the entire ML life cycle. It is our
              job to offer the holistic platform you require to manage your teams,
              all projects, permission levels or budgets. Enjoy frictionless ML development
              in the cloud or on premise.
            </p>
            <ul className="featured-list-content-list">
              <li className="">
                Secure via fine-grained permission management
              </li>
              <li className="">
                All knowledge is instant and always accessible
              </li>
              <li className="">
                Manage your budget and computing resources
              </li>
            </ul>
          </div>
          <div className="featured-list-image border-rounded">
            <Img
              className=""
              src="/images/dive/MLReef_resources.png"
              alt="mlreef | Manage your knowledge base, teams and budgets"
            />
          </div>
        </div>
      </div>

      <Sponsors sponsors={data.sponsors} />

      <section className="banner-wide">
        <div className="banner-wide-title">
          MLReef works on your infrastructure - the cloud and on premise!
        </div>
        <p className="banner-wide-text">
          Use the cloud base solution for an easy and maintenance free environment with
          full access to community projects. Or install MLReef on your servers in a single
          installation using your computing cluster.
        </p>
        <MLink openApart to="mailto:hello@mlreef.com" className="btn btn-basic-primary mt-2">
          Get in touch!
        </MLink>
      </section>

      <section className="home-page-section examples">
        <HomeStatement
          title="Your Machine Learning development on one platform"
          text="Instead of disconnected toolchains, use MLReef to run your pipelines, process your data, version and track your experiments and model, manage your teams and members, ... and much more.."
        />
      </section>

      <ContentCards className="pb-4" />

      <div className="banner-short dark card mt-4 mb-5">
        <div className="banner-short-container card-container banner pt-2 pb-4">
          <div className="m-auto">
            <p>
              Start to operationalize and scale your AI/ML efforts and increase collaboration
              and efficiency!
            </p>
            <a
              href="https://www.mlreef.com/book-a-demo"
              className="btn btn-outline-primary mx-auto mt-3"
              target="_blank"
              rel="noreferrer noopener"
            >
              Book your demo
            </a>
          </div>
        </div>
      </div>
    </LayoutTabs>
  );
};

export const query = graphql`
  query EnterprisePageData {
    dataYaml {
      homepage {
        sponsors {
          logo
          title
          link
        }
      }
    }
  }
 `;

export default EnterprisePage;
